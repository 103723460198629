<template>
  <div class="list-view">
    <page-title>
      <el-button
        v-if="lv == 'ROLE_ADMIN'"
        @click="onPass"
        type="primary"
        :disabled="fetchingData || downloading"
        class="filter-item"
      >
        一键通过
      </el-button>
      <!-- <el-button @click="addRow" type="primary" icon="el-icon-plus" :disabled="fetchingData || downloading" class="filter-item">
                新增
            </el-button>
            <el-button @click="download" icon="el-icon-upload2" :loading="downloading" :disabled="fetchingData" class="filter-item">
                导出
            </el-button> -->
    </page-title>
    <div class="filters-container">
      <el-select
        v-model="org"
        @change="initData"
        class="filter-item"
      >
        <el-option
          label="个人"
          :value="false"
        ></el-option>
        <el-option
          label="企业"
          :value="true"
        ></el-option>
      </el-select>
      <el-select
        v-model="status"
        clearable
        placeholder="状态"
        @change="initData"
        class="filter-item"
      >
        <el-option
          v-for="item in statusOptions.slice(1, 4)"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-input
        placeholder="搜索..."
        v-model="search"
        clearable
        class="filter-item search"
        @keyup.enter.native="initData"
      >
        <el-button
          @click="initData"
          slot="append"
          icon="el-icon-search"
        > </el-button>
      </el-input>
    </div>
    <el-table
      :data="tableData"
      row-key="id"
      ref="table"
      header-row-class-name="table-header-row"
      header-cell-class-name="table-header-cell"
      row-class-name="table-row"
      cell-class-name="table-cell"
      :height="tableHeight"
      v-loading="fetchingData"
    >
      <el-table-column
        v-if="multipleMode"
        align="center"
        type="selection"
        width="50"
      > </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="100"
      > </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        width="100"
      > </el-table-column>
      <el-table-column
        prop="realName"
        :label="org ? '法人' : '姓名'"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <template v-if="org">
        <el-table-column
          prop="orgName"
          label="企业名称"
          show-overflow-tooltip
          key="1"
        ></el-table-column>
        <el-table-column
          prop="orgNo"
          label="统一社会信用代码"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="orgLicense"
          label="营业执照"
          align="center"
          width="80"
          key="2"
        >
          <template v-slot="{ row }">
            <el-image
              style="width: 30px; height: 30px"
              :src="row.orgLicense"
              fit="cover"
              :preview-src-list="[row.orgLicense]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="orgLicenseExpire"
          label="营业执照有效期"
          min-width="160"
          show-overflow-tooltip
          key="3"
        >
          <template v-slot="{ row }">
            <span>{{ row.orgLicenseExpire || '长期' }}</span>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column
          prop="phone"
          label="手机"
          min-width="150"
          show-overflow-tooltip
          key="4"
        > </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          min-width="200"
          show-overflow-tooltip
          key="5"
        > </el-table-column>
      </template>

      <el-table-column
        prop="idNo"
        label="身份证"
        min-width="200"
        show-overflow-tooltip
      > </el-table-column>
      <el-table-column
        prop="idFront"
        label="身份正面照"
        width="120"
        align="center"
      >
        <template v-slot="{ row }">
          <el-image
            style="width: 30px; height: 30px"
            :src="row.idFront"
            fit="cover"
            :preview-src-list="[row.idFront]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="idBack"
        label="身份证反面照"
        width="120"
        align="center"
      >
        <template v-slot="{ row }">
          <el-image
            style="width: 30px; height: 30px"
            :src="row.idBack"
            fit="cover"
            :preview-src-list="[row.idBack]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="审核状态"
        :formatter="statusFormatter"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="150"
      >
        <template slot-scope="{ row }">
          <el-button
            @click="pass(row)"
            type="primary"
            size="mini"
            plain
            v-if="row.status === 'PENDING'"
          >
            通过
          </el-button>
          <el-button
            @click="deny(row)"
            type="danger"
            size="mini"
            plain
            v-if="row.status === 'PENDING'"
          >
            拒绝
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper">
      <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
      <el-pagination
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'IdentityAuthList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/identityAuth/all',
            downloading: false,
            statusOptions: [
                { label: '未认证', value: 'NOT_AUTH' },
                { label: '认证中', value: 'PENDING' },
                { label: '已认证', value: 'SUCCESS' },
                { label: '失败', value: 'FAIL' }
            ],
            status: 'PENDING',
            org: false,
            lv: this.$store.state.userInfo.authorities[0].name
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },

    methods: {
        statusFormatter(row, column, cellValue, index) {
            let selectedOption = this.statusOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, status: this.status, org: this.org } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/identityAuthEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/identityAuthEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/identityAuth/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/identityAuth/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        },
        onPass() {
            this.$confirm('确定一键通过全部？')
                .then(res => {
                    return this.$http.get('/identityAuth/oneButtonPass');
                })
                .then(res => {
                    this.$message.success('成功');
                    this.getData();
                })
                .catch(e => {
                    // this.$message.error(e.error);
                });
        },
        pass(row) {
            this.$confirm('确定通过？')
                .then(res => {
                    return this.$http.post('/identityAuth/pass', { id: row.id });
                })
                .then(res => {
                    this.$message.success('成功');
                    this.getData();
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        deny(row) {
            this.$confirm('确定拒绝？')
                .then(res => {
                    return this.$http.post('/identityAuth/deny', { id: row.id });
                })
                .then(res => {
                    this.$message.success('成功');
                    this.getData();
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
